@import 'Asset/styles/css/ddscss.css';
@import 'Asset/styles/css/style.css';

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,700;6..12,800&display=swap');

$primary: #29A0ED;
$dark-blue: #0D5183;
$dark-gray:#9E9E9E;

body{
  font-family: 'Nunito Sans', sans-serif !important;
  background-color: #F6F6F6;
}

h1, h2, h3, h4, h5{
  font-weight: 700;
  color: #231F20;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// .App{
//   overflow-y: auto;
// }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-card{
  border-radius: 15px;
  background: #fff;
}

.searchbox{
  border-radius:22px;
  background: #fff;
  label{
    font-weight: 700;
  }
  .form-control{
    border: 0;
    height: calc(1em + 0.75rem + 2px);
    padding: 0;
    &:focus{
      box-shadow: 0 0 0 0rem rgba(9, 22, 104, 0);
    }
  }
  .btn-primary{
    background-color: $primary !important;
    border-color: $primary !important;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}
.card-shadow{
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
}

.border-left-right-1{
  border-left: 1px solid $dark-gray;
  border-right: 1px solid $dark-gray;
}

select option[value="select"] {
  display: none;
}

.border-right-1{
  border-right: 1px solid $dark-gray;
}
.custom-stepper{
  .MuiStep-horizontal{
    padding: 0;
  }
  .MuiPaper-root{
    background-color: transparent;
    padding: 24px 0px;
    .MuiStepConnector-line{
      border-color:#f6f6f6;
    }
    .MuiStep-root{
      .MuiStepLabel-root{
        padding: 5px 10px;
        border-radius: 20px;
        border: 1px solid $primary;
        background-color: $primary;
        &.Mui-disabled{
          border: 1px solid $primary;
          background-color: #fff;
          .MuiStepIcon-root{
            &.MuiStepIcon-root{
              color: $primary;
              .MuiStepIcon-text{
                fill: #fff;
              }
            } 
          }
          .MuiStepLabel-labelContainer{
            .MuiStepLabel-label{
            color: $primary;
            }
          }
        }
        .MuiStepIcon-root{
          &.MuiStepIcon-active{
          color: #fff;
          .MuiStepIcon-text{
            fill: $primary;
          }
          }
        }
        .MuiStepLabel-label{
          &.MuiStepLabel-active{
          color: #fff;
          }
        }
      }
      &.MuiStep-completed{
        .MuiStepLabel-root{
          padding: 5px 10px;
          border-radius: 20px;
          border: 1px solid $dark-blue;
          background-color: $dark-blue;
          .MuiStepIcon-root{
            color: #fff;
            .MuiStepIcon-text{
              fill: $primary;
            }
            }
          }
          .MuiStepLabel-label{
            color: #fff; 
          }
        }
      }
    }
}

.pick-time{
  .nav-tabs{
    border-bottom: 1px solid #fff;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    color: $dark-blue;
    font-size: 18px;
    font-weight: 700;
    border-color: #fff #fff $dark-blue !important;
  }
  .nav-tabs .nav-link{
    font-size: 18px;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
    border-color: #fff;
  }
  transition: all 2s ease;
}

.time-list{
  .time-slot{
    width: 100px;
    max-width: 200px;
    padding: 10px 15px;
    border: 1px solid $primary;
    color: $primary;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    transition: ease-in all 5ms;
    cursor: pointer;
    &:hover{
      background-color: $primary;
      box-shadow: 0 4px 4px rgba(0,0,0,0.1);
      color: #fff;
      transform: scale(0.97); 
      transition: all .2s ease;
    }
    &.active{
      background-color: $primary;
      color: #fff;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-flow: column wrap;
    height: 20rem;
  }
}

.doctor-name-img{
  .doctor-img{
    height: 70px;
    width: 70px;
    border-radius: 50px;
    overflow: hidden;
    img{
      background-position: center;
      background-size: cover;
      width: 100%;
      height: auto;
    }
  }
  p{
    font-size: 16px;
    color: #1C2430;
    font-weight: 700;
  }
}

.activeTimeSlot{
  background-color: $primary !important;
  color: #fff !important;
  transition: all .2s ease;
  animation: selectedButton 0.2s normal forwards ease-in-out;
}
.MuiStepConnector-root, .MuiStepConnector-horizontal, .Mui-disabled {
  display: none;
}

.MuiPaper-root, .MuiStepper-root, .MuiStepper-horizontal, .MuiPaper-elevation0 {
  display: flex;
  justify-content: space-between;
}

.profile-information-btn-holder{
  button{
    border-radius: 5px;
    border: 1px solid $dark-blue;
    color: $dark-blue;
    padding: 10px 15px;
    width: 200px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    &:hover{
      background-color: $dark-blue !important;
      color: #fff;
    }
  }
}

.bolder-text{
  font-weight: 800;
}

.profile-form{
  .form-label{
    color: #231F20;
    font-weight: 700;
    font-size: 12px;
  }
  .form-control{
    border: 1px solid $primary;
    border-radius: 8px;
  }
}
.btn.btn-primary{
  background-color: $primary !important;
  border-color: $primary !important;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

.accordionBtn:hover{
  background-color: $primary !important;
  color: #fff !important;
}

.errorControl {
  border: 1px solid red !important;
  &:focus{
    box-shadow: 0 0 0 0.25rem rgba(255, 99, 71, 0.4) !important; 
  }
}

.dark-bg{
  background-color: #F6F6F6;
  border-radius: 10px;
}

.review-section{
  h4{
    font-size: 18px;
    &.text-primary{
      color: $primary !important;
    }
  }
  p{
    font-size: 16px;
    font-weight: 600;
  }
  .form-check-label{
    font-size: 16px;
    line-height: 1.4;
    color: #000;
    margin-left: 20px;
  }
  .form-check-input{
    height: 20px;
    width: 20px;
  }
}

.confirmation{
  p{
    font-size: 15px;
  }
  h1{
    font-size: 24px;
  }
  h4{
    font-size: 18px;
  }
}
.text-green{
  color: #3A8A6D;
}

.text-red{
  color: #f56f4e;
}

.loader-60devs .loader-60devs-progress{
  height:5px !important;
  background-color:#29A0ED !important;
}

.card__title { 
    padding: 8px; 
    font-size: 22px; 
    font-weight: 700; 
} 
  
.card__title.loading { 
    height: 1.75rem; 
    width: 100%; 
    // margin: 1rem; 
    margin: 1rem 0 1rem 0;
    border-radius: 3px; 
} 

/* The loading Class */ 
.loading { 
    position: relative; 
    background-color: #e2e2e2; 
} 

/* The moving element */ 
.loading::after { 
    display: block; 
    content: ""; 
    position: absolute; 
    width: 100%; 
    height: 100%; 
    transform: translateX(-100%); 
    background: -webkit-gradient(linear, left top, 
                right top, from(transparent),  
                color-stop(rgba(255, 255, 255, 0.2)), 
                to(transparent)); 
                  
    background: linear-gradient(90deg, transparent, 
            rgba(255, 255, 255, 0.2), transparent); 

    /* Adding animation */ 
    animation: loading 0.8s infinite; 
} 

/* Loading Animation */ 
@keyframes loading { 
    100% { 
        transform: translateX(100%); 
    } 
} 

@keyframes selectedButton {
  from {
    transform: scale(0.97);
  }
  to {
    transform: scale(0.95);
  }
}

.react-datepicker__close-icon {
    display: flex;
    flex-direction: inherit;
    align-items: flex-start;
    margin-top: 0.75rem;
}

html {
  scroll-behavior: smooth;
  transition: ease all 1s
}

.date_display {
  text-transform: none;
}

// .MuiMobileStepper-progress {
//   width: 100% !important;
// }

// .MuiMobileStepper-root {
//   background: transparent !important;
//   padding-left: 0px !important;
//   padding-right: 0px !important;
// }

// .MuiLinearProgress-root {
//   height: 8px !important;
//   border-radius: 6px;
// }

// .MuiLinearProgress-barColorPrimary{
//   background-color: #29A0ED  !important;
//   height: 10px;
// }

// .MuiLinearProgress-colorPrimary {
//   background-color: #e2e2e2  !important;
// }

// .mt-10 {
//   margin-top: 10;
// }

// .vh-10 {
//   height: 10vh;
// }


.intake-form{

  .question-slot{

    width: 100%;

    margin-bottom: 15px;

    padding: 10px 15px;

    border: 1px solid $dark-gray;

    color: $dark-gray;

    text-align: center;

    border-radius: 5px;

    background-color: #fff;

    transition: ease all 5ms;

    cursor: pointer;

    &:hover{

      background-color: $primary;

      box-shadow: 0 4px 4px rgba(0,0,0,0.1);

      color: #fff;

      transform: scale(0.98); 

      transition: all .2s ease;

      border: 1px solid $primary;

    }

    &.active{

      border: 1px solid $primary;

      background-color: $primary;

      color: #fff;

    }

  }

  .MuiMobileStepper-progress{

    width: 100%;

  }

  .MuiLinearProgress-colorPrimary{

    background-color: #F2F4F7;

  }

  .MuiLinearProgress-root{

    height: 8px;

    border-radius: 4px;

  }

  .MuiLinearProgress-barColorPrimary{

    background-color: $primary;

  }

  .MuiMobileStepper-root{

    background-color: transparent;

    max-width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;

  }

}

.form-control:disabled {
  background-color: transparent;
  border-color: rgba(0,0,0,0.2);
}

.payment_modal {
   z-index: 9999999999 !important ;
   height: 80vh;
   width: 400px;
}

.btn-outline-primary{
  color:$primary ;border-color:$primary;
  border-radius: 4px;
  width: 6rem;
}
.btn-outline-primary:hover { 
  color: $primary ;background-color:#fff ;border-color:$primary;
  border-radius: 4px;
}
.btn-outline-primary:focus,.btn-outline-primary.focus{
  box-shadow:0 0 0 .2rem rgba(41, 160, 237, 0.5);
  border-radius: 4px;
}
.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color:$primary;background-color:transparent;
  border-radius: 4px;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{
  color:$primary;background-color:#fff; border-color:$primary;
  border-radius: 4px;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(41, 160, 237, 0.5);
  border-radius: 4px;
}
